//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { colors } from '@/components/global/Tag/'
import algoliasearch from 'algoliasearch/lite'
import 'instantsearch.css/themes/algolia-min.css'

const searchClient = algoliasearch(
  'P0V72MWCNL',
  '807b988ae3def36921181f33904b7f5c'
)
export default {
  props: {
    isOpened: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      searchClient,
      timeoutRef: null,
    }
  },
  computed: {
    optionalFilters() {
      if (!this.scheme && !this.isTutorial) return []

      return [`scheme:${this.scheme || 'tutorial'}`]
    },
    facets() {
      if (this.scheme || this.tutorial) return ['scheme']

      return []
    },
    scheme() {
      return this.$route.params.scheme
    },
    isTutorial() {
      return this.$route.path.includes('tutorials')
    },
    indexName() {
      const indexNameFromEnv = process.env.ALGOLIA_SEARCH_INDEX_NAME

      if (!indexNameFromEnv) {
        return 'dev_api'
      }

      return process.env.ALGOLIA_SEARCH_INDEX_NAME
    },
    searchIsDisabled() {
      return this.indexName === 'disabled'
    },
  },
  destroyed() {
    clearTimeout(this.timeoutRef)
  },
  methods: {
    focusInput() {
      const searchInputEl = this.$el.querySelector('input.input')

      if (searchInputEl) {
        searchInputEl.focus()
      }
    },
    handleFocusOut(e) {
      if (!this.$refs.searchModalContainer.contains(e.relatedTarget)) {
        this.$store.commit('search/closeSearchModal')
        const focusableElement = document.querySelector(
          '.content a[href], .content input, .content button, .content [tabindex]:not([tabindex="-1"]'
        )

        if (focusableElement) {
          setTimeout(() => {
            focusableElement.focus()
          }, 10)
        }
      }
    },
    timedOutFocusInput() {
      clearTimeout(this.timeoutRef)
      this.timeoutRef = setTimeout(this.focusInput, 300)
    },
    closeSearchModal() {
      this.$store.commit('search/closeSearchModal')
    },
    handleEscape() {
      const searchInputValue = this.$el.querySelector('input.input').value

      if (searchInputValue === '') {
        this.closeSearchModal()
      }
    },
    getIconFromCategory(category) {
      // hardcoded for now
      return 'form3-document-icon'
    },
    getSchemeTagColor(scheme) {
      switch (scheme) {
        case 'sepa-direct-debit':
          return colors.sepa
        case 'sepa-instant-credit-transfer':
          return colors.sepai
        case 'fps-direct':
          return colors.fps
        case 'bacs':
          return colors.bacs
        case 'indirect-via-lhv':
          return colors.indirect
        case 'tutorial':
          return colors.tutorial
        default:
          return colors.gray
      }
    },
    getResultDomArray() {
      return Array.from(this.$el.querySelectorAll('.result_wrapper'))
    },
    getActiveResultIndex() {
      if (!document.activeElement) {
        return -1
      }

      return this.getResultDomArray().indexOf(document.activeElement)
    },
    handleUpKeyPress() {
      const currentIndex = this.getActiveResultIndex()
      const resultArray = this.getResultDomArray()
      if (currentIndex > 1) {
        resultArray[currentIndex - 1].focus()
      }
    },
    handleDownKeyPress() {
      const currentIndex = this.getActiveResultIndex()
      const resultArray = this.getResultDomArray()
      if (currentIndex < resultArray.length - 2) {
        resultArray[currentIndex + 1].focus()
      }
    },
    handleEnterKeyPress() {
      const currentIndex = this.getActiveResultIndex()
      const resultArray = this.getResultDomArray()
      resultArray[currentIndex].querySelector('a').click()
    },
    handleAlphanumericKeyPress(key) {
      const searchInput = document.querySelector('.search-input:not(:focus)')
      if (searchInput) {
        if (key.length === 1 || key === 'Backspace') {
          searchInput.focus()
        }
      }
    },
    handleKeydown(event) {
      switch (event.key) {
        case 'ArrowDown':
          return this.handleDownKeyPress()
        case 'ArrowUp':
          return this.handleUpKeyPress()
        case 'Enter':
          return this.handleEnterKeyPress()
        default:
          this.handleAlphanumericKeyPress(event.key)
      }
    },
  },
}
